<template>
  <div class="chatSettings">
    <h3>
      Chat Settings
    </h3>
    <hr class="mb-2">
    <b-card
      style="max-width: 30em;"
      border-variant="primary"
    >
      <div>
        <b-card-text class="mb-1">
          <strong>Chat Mode</strong>
        </b-card-text>
        <b-form-select
          v-model="selected"
          :options="options"
        />
      </div>
      <b-card-text class="mt-2 mb-1">
        <strong>Allow GIFs</strong>
      </b-card-text>
      <b-form-checkbox
        checked="true"
        name="check-button"
        switch
      >
        All GIF images in chat
      </b-form-checkbox>
      <b-card-text class="mt-2 mb-1">
        <strong>Allow Links</strong>
      </b-card-text>
      <b-form-checkbox
        checked="true"
        name="check-button"
        switch
      >
        Allow links in chat
      </b-form-checkbox>
      <b-card-text class="mt-2 mb-1">
        <strong>Automod Enabled</strong>
      </b-card-text>
      <b-form-checkbox
        checked="true"
        name="check-button"
        switch
      >
        Enable base screening of chat messages
      </b-form-checkbox>
      <b-card-text class="mt-2 mb-1">
        <strong>2FA Required</strong>
      </b-card-text>
      <b-form-checkbox
        checked="true"
        name="check-button"
        switch
      >
        Require chatters to have 2FA enabled
      </b-form-checkbox>
      <div>
        <b-card-text class="mt-1 mb-1">
          <strong>Min. Account Age</strong><br>
          <small>A user's account must be at least this old in order to chat</small>
        </b-card-text>
        <b-form-select
          v-model="channelChatAccountAge"
          :options="accountAge"
        /></div></b-card>
  </div>
</template>

<script>
import {
  BFormCheckbox, BFormSelect, BCardText, BCard, BFormInput, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BFormCheckbox,
    BFormInput,
    BFormSelect,
    BCardText,
    BCard,
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      twitchChatSync: '',
      selected: 'a',
      channelChatAccountAge: 'a',
      options: [
        { value: 'a', text: 'Standard' },
        { value: 'b', text: 'Sub Only' },
        { value: 'c', text: 'Emote Only' },
        { value: 'd', text: 'Follower Only' },
      ],
      accountAge: [
        { value: 'a', text: 'Allow any account age' },
        { value: 'b', text: '1 Day Old' },
        { value: 'c', text: '1 Week Old' },
        { value: 'd', text: '1 Month Old' },
        { value: 'd', text: '3 Months Old' },
        { value: 'd', text: '1 Year Old' },
      ],
    }
  },
  async created() {
    const chatLinks = await this.getChatLinks()
    if (chatLinks.integrations.length > 0) {
      // eslint-disable-next-line prefer-destructuring
      this.twitchChatSync = chatLinks.integrations[0].twitch
    }
  },
  methods: {
    async updateChatSync(username) {
      const result = await this.chatLink(username)
      if (result.success) {
        this.$bvToast.toast('Twitch Chat Link Updated', {
          title: 'Success',
          variant: 'success',
          solid: true,
        })
      } else {
        this.$bvToast.toast('Whoops, looks like something went wrong.', {
          title: 'Error.',
          variant: 'danger',
          solid: true,
        })
      }
    },
  },
}
</script>
<style>
.chatSettings {
  max-width: 400px;
  display: block;
}
</style>
