 <template>
  <div>
    <UploadImages @changed="handleImages" />
    {{ files }}
  </div>
</template>
<script>
import UploadImages from "vue-upload-drop-images";
export default {
  components: {
    UploadImages,
  },
  data() {
    return {
      files: null,
    };
  },
  methods: {
    handleImages(files) {
       for (File in files) {

    console.log(`${File}: ${files[File]}`);
}
    //   for (let i = 0; i < files.length; i++) {
    //     this.files.push(files[i])
    //   }
    },
  },
};
</script>
