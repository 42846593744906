<template>
  <div>
  <h2>Integrations</h2>
  <p class="mb-3">Level up your stream with one of our many built-in integrations</p>
  <h3 class="mb-1 mt-2">Streaming Platforms</h3>
  <hr class="mt-0 mb-2" style="max-width: 700px; margin-left:0;"/>
  <b-card style="max-width: 700px" border-variant="primary">
      <a href="https://twitch.tv" target="_blank">
      <img
        src="https://assets.brimecdn.com/cdn-cgi/image/width=120,quality=100/Twitch-Logo.png"
        width="120"
        class="mt-0 mb-2"
      />
      </a>
      <b-button
        v-if="!oauth_list.find(o => o.platform === 'twitch')"
        size="sm"
        variant="success"
        class="connectButton"
        @click="connect('twitch')"
      >
        Connect</b-button
      >
      <b-button
        v-if="oauth_list.find(o => o.platform === 'twitch')"
        size="sm"
        class="connectButton"
        @click="disconnect('twitch')"
      >
        Disconnect</b-button
      >
      <h4 style="color: white">Twitch</h4> This integration supports automatic configuration of multi-stream and multi-chat.
      <b-button
        v-if="oauth_list.find(o => o.platform === 'twitch')"
        size="sm"
        class="mt-2"
        variant="primary"
        @click="$router.push('/studio/multistreaming');"
      ><feather-icon
      icon="Share2Icon"
      class="mr-50"
    />
    <span class="align-middle">Manage Multistream</span></b-button
      >
      <b-button
        v-if="oauth_list.find(o => o.platform === 'twitch')"
        size="sm"
        class="mt-2 ml-1"
        variant="primary"
        @click="$router.push('/studio/multichat');"
      ><feather-icon
      icon="MessageSquareIcon"
      class="mr-50"
    />
    <span class="align-middle">Manage Multichat</span></b-button
      >
    </b-card>


    <!---YOUTUBLE-->
    <b-card style="max-width: 700px" border-variant="primary">
      <a href="https://youtube.com" target="_blank">
      <img
        src="https://assets.brimecdn.com/cdn-cgi/image/width=120,quality=100/youtube_custom_logo.png"
        width="120"
        class="mt-0 mb-2"
      />
      </a>
      <img title="Sign in with YouTube" @click="connect('youtube')" v-if="!oauth_list.find(o => o.platform === 'youtube')" class="connectButton" src="https://assets.brimecdn.com/sign_in_with_youtube.svg" width="180px" style="cursor:pointer;">
    <b-button
        v-if="oauth_list.find(o => o.platform === 'youtube')"
        size="sm"
        class="connectButton"
        @click="disconnect('youtube')"
      >
        Disconnect</b-button
      >
      <h4 style="color: white">YouTube</h4> This integration supports automatic configuration of multi-stream and multi-chat.
      <b-button
        v-if="oauth_list.find(o => o.platform === 'youtube')"
        size="sm"
        class="mt-2"
        variant="primary"
        @click="$router.push('/studio/multistreaming');"
      ><feather-icon
      icon="Share2Icon"
      class="mr-50"
    />
    <span class="align-middle">Manage Multistream</span></b-button
      >
      <b-button
        v-if="oauth_list.find(o => o.platform === 'youtube')"
        size="sm"
        class="mt-2 ml-1"
        variant="primary"
        @click="$router.push('/studio/multichat');"
      ><feather-icon
      icon="MessageSquareIcon"
      class="mr-50"
    />
    <span class="align-middle">Manage Multichat</span></b-button
      >
    </b-card>
    <!---END YOUTUBLE-->


    <b-card style="max-width: 700px" border-variant="primary">
      <a href="https://trovo.live" target="_blank">
      <img
        src="https://assets.brimecdn.com/cdn-cgi/image/width=120,quality=100/Trovo_Logo.png"
        width="120"
        class="mt-1 mb-3"
      />
      </a>
      <b-button
        v-if="!oauth_list.find(o => o.platform === 'trovo')"
        size="sm"
        variant="success"
        class="connectButton"
        @click="connect('trovo')"
      >
        Connect</b-button
      >
      <b-button
        v-if="oauth_list.find(o => o.platform === 'trovo')"
        size="sm"
        class="connectButton"
        @click="disconnect('trovo')"
      >
        Disconnect</b-button
      >
      <h4 style="color: white">Trovo</h4> This integration supports automatic configuration of multi-stream and multi-chat.
      <b-button
        v-if="oauth_list.find(o => o.platform === 'trovo')"
        size="sm"
        class="mt-2"
        variant="primary"
        @click="$router.push('/studio/multistreaming');"
      ><feather-icon
      icon="Share2Icon"
      class="mr-50"
    />
    <span class="align-middle">Manage Multistream</span></b-button
      >
      <b-button
        v-if="oauth_list.find(o => o.platform === 'trovo')"
        size="sm"
        class="mt-2 ml-1"
        variant="primary"
        @click="$router.push('/studio/multichat');"
      ><feather-icon
      icon="MessageSquareIcon"
      class="mr-50"
    />
    <span class="align-middle">Manage Multichat</span></b-button
      >
    </b-card>
  <h3 class="mb-1 mt-2">Alerts &amp; Widgets</h3>
  <hr class="mt-0 mb-2" style="max-width: 700px; margin-left:0;"/>
    <b-card style="max-width: 700px" border-variant="primary">
    <a href="https://streamlabs.com" target="_blank">
      <img
        src="https://assets.brimecdn.com/streamlabs_logo.png"
        width="200"
        class="mt-1 mb-1"
      />
      </a>
      <b-button
        v-if="!oauth_list.find(o => o.platform === 'streamlabs')"
        size="sm"
        variant="success"
        class="connectButton"
        @click="connect('streamlabs')"
      >
        Connect</b-button
      >
      
      <b-button
        v-if="oauth_list.find(o => o.platform === 'streamlabs')"
        size="sm"
        class="connectButton"
        @click="disconnect('streamlabs')"
      >
        Disconnect</b-button
      >
      <br /><br />
      <h4 style="color: white">Streamlabs</h4> This integration supports sending Brime platform alerts to Streamlabs.<br/> It also features the ability to accept Streamlabs donations.
      <div
        v-if="oauth_list.find(o => o.platform === 'streamlabs')"
        style="position: relative; bottom: 15px; margin-top: 40px"
      >
        <hr />
        <h4 class="mb-2">Test Your Alerts</h4>

        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="primary"
          size="sm"
          class="mr-1"
          @click="testSlobsAlerts('follow')"
        >
          Follow Alert
        </b-button>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="primary"
          size="sm"
          class="mr-1"
          @click="testSlobsAlerts('subscription')"
        >
          Sub Alert
        </b-button>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="primary"
          size="sm"
          @click="testSlobsAlerts('donation')"
        >
          Dono Alert
        </b-button>
      </div>
      <!-- primary -->
      <div v-if="oauth_list.find(o => o.platform === 'streamlabs')">
        <b-card-text class="mb-0 mt-1">
          <h4 class="mb-1 mt-1">Enable Streamlabs Donate Button</h4>
        </b-card-text>
        <b-form-checkbox
          :disabled="donateSettings.platform && donateSettings.platform !== 'streamlabs'"
          :checked="donateSettings.platform == 'streamlabs'"
          class="custom-control-primary"
          name="check-button"
          switch
          @change="donateSwitch()"
        >
          <span class="switch-icon-left">
            <feather-icon icon="DollarSignIcon" />
          </span>
          <span class="switch-icon-right">
            <feather-icon icon="DollarSignIconOff" />
          </span>
        </b-form-checkbox>
      </div>
    </b-card>
  <h3 class="mb-1 mt-2">Tips / Donations</h3>
  <hr class="mt-0 mb-2" style="max-width: 700px; margin-left:0;"/>
    <b-card style="max-width: 700px" border-variant="primary">
      <img
        src="https://cdn.streamelements.com/assets/homepage/SE_logo_600x129px_user_dashboard%403x.png"
        width="220px"
      /><b-button
       v-if="donateSettings.platform !== 'streamelements'"
       :disabled="donateSettings.platform == 'kofi' && donateSettings.platform !== 'streamelements' || donateSettings.platform == 'streamlabs'"
        variant="primary"
        class="float-right integrationButton"
        v-b-modal.donateModal
        @click="tipLogo='https://cdn.streamelements.com/assets/homepage/SE_logo_600x129px_user_dashboard%403x.png', logoWidth='100%', selectedPlatform='streamelements'"
        >Enable Donations</b-button
      >
      <b-button
        v-if="donateSettings.platform == 'streamelements'"
        class="float-right integrationButton"
        @click="deleteIntegration('streamelements')"
        >Disable Donations</b-button
      >
    </b-card>
    <b-card style="max-width: 700px" border-variant="primary">
      <img
        src="https://f004.backblazeb2.com/file/brime-assets/ko-fi-logo.png"
        width="130px"
      /><b-button
        v-if="donateSettings.platform !== 'kofi'"
        :disabled="donateSettings.platform == 'streamelements' || donateSettings.platform == 'streamlabs'"
        variant="primary"
        class="float-right integrationButton"
        v-b-modal.donateModal
        @click="tipLogo='https://assets.brimecdn.com/ko-fi-logo.png', logoWidth='50%', selectedPlatform='kofi'"
        >Enable Donations</b-button
      >
      <b-button
        v-if="donateSettings.platform == 'kofi'"
        class="float-right integrationButton"
        @click="deleteIntegration('kofi')"
        >Disable Donations</b-button
      >
    </b-card>
  <h3 class="mb-1 mt-2">Streaming Tools</h3>
  <hr class="mt-0 mb-2" style="max-width: 700px; margin-left:0;"/>
    <b-card style="max-width: 700px" border-variant="primary">
      <img
        src="https://assets.casterlabs.co/logo/casterlabs_full_white.png"
        width="160px"
      /><b-button
        variant="primary"
        class="float-right integrationButton"
        @click="openLink('https://casterlabs.co/')"
        >Visit Website</b-button
      >
    </b-card>
   <!-- <b-card style="max-width: 700px" border-variant="primary">
      <img
        src="https://assets.brimecdn.com/brime/better_brime.png"
        height="50px"
      /><span class="ml-2 integrationLabel">Better Brime</span
      ><b-button
        variant="primary"
        class="float-right integrationButton"
        @click="openLink('https://betterbri.me/')"
        >Visit Website</b-button
      >
    </b-card> -->
    <b-card style="max-width: 700px" border-variant="primary">
      <img
        src="https://f004.backblazeb2.com/file/brime-assets/multistream_tools_logo.png"
        width="160px"
      /><b-button
        variant="primary"
        class="float-right integrationButton"
        @click="openLink('https://multistream-tools.live/')"
        >Visit Website</b-button
      >
    </b-card>
    <div class="mt-4 mb-5">
    Interested in integrating with us? <a href="https://brime.readme.io/">Click here</a><br/>
    Join the <a href="https://discord.gg/Qf5A9kEus8" target="_blank">Brime Developer Discord</a>
    </div>
    <b-modal
      id="donateModal"
      ref="donateModal"
      cancel-variant="secondary"
      :hide-footer="true"
      centered
      size="sm"
      title="Enable Tipping (Donations)"
    ><center><img :src="tipLogo" :width="logoWidth" class="mb-1"></center>
      <b-form-group
        :label="labeltext(selectedPlatform)"
        label-for="basicInput"
      >
        <b-form-input
          id="basicInput"
          v-model="donateAddUsername"
          placeholder="Enter Username"
        />
      </b-form-group>
      <b-button 
      @click="donateIntegrationAdd(selectedPlatform, donateAddUsername)"
      variant="primary"
      class="mt-2 btn-block"
      >
      Enable
      </b-button>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BFormGroup,
  BButton,
  BButtonGroup,
  BFormCheckbox,
  BCardText,
  BForm,
  BFormInput,
  
} from "bootstrap-vue";
import { data } from "vue-echarts";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BButton,
    BFormGroup,
    BButtonGroup,
    BFormCheckbox,
    ToastificationContent,
    BCardText,
    BForm,
    BFormInput,
  },
   directives: {
    Ripple,
  },
  data() {
    return {
      oauth_list: [],
      otherDonationEnabled: false,
      selectedPlatform: '',
      donateAddUsername: '',
      donateSettings: {},
      tipLogo: '',
      logoWidth: '',
      connectURL:
        "https://streamlabs.com/api/v1.0/authorize?response_type=code&client_id=ytHZEH1seHQXUW1ExwjXj4rruwfwY5o7VJ2pFH7S&scope=donations.create%20donations.read%20alerts.create%20socket.token%20alerts.write%20credits.write%20profiles.write%20jar.write%20wheel.write%20mediashare.control&redirect_uri=https%3A%2F%2Fbrime.tv%2Fintegrations%2Fstreamlabs",
      slobsLinked: false,
      donateEnabled: false,
    };
  },
  async mounted() {
    window.history.replaceState('', 'Brime Studio - Integrations', '/studio/integrations');
    if(this.$route.query.info == 'linked'){
      this.$bvToast.toast(`Your ${this.$route.query.platform} is now connected.`, {
        title: "Great Success",
        variant: "success",
        solid: true,
      });
    }
    // let result = await this.checkSlobs();
    this.oauth_list = await this.getOAuthList()
    // this.slobsLinked = result.linked;
    // this.donateEnabled = result.donate_enabled;
    this.donateSettings = await this.getDonationSettings()
    if(this.donateSettings.platform){
      this.otherDonationEnabled = true;
    }
  },
  methods: {
    async connect(platform){
      console.log('connecting to ' + platform)
      const lookupAuth = await this.getAuthURL(platform);
      window.location.replace(lookupAuth.auth_endpoint);
    },
    async disconnect(platform){
      const result = await this.disonnectOauth(platform);
        if(result.success){
          this.oauth_list = await this.getOAuthList()
        }
    },
    labeltext(platform){
      if(platform == 'streamelements'){
        return 'StreamElements Username'
      } else if(platform == 'kofi'){
        return 'Ko-fi Username'
        }
    },
    async deleteIntegration(platform){
      let deleteRequest = await this.deleteDonationIntegration(platform)
      this.donateSettings = await this.getDonationSettings()
      if(this.donateSettings.platform !== null){
      this.otherDonationEnabled = false;
    }
    },
    async donateIntegrationAdd(platform, username){
      const insertQuery = await this.addDonationIntegration(platform, username)
      this.$refs["donateModal"].hide()
      this.donateSettings = await this.getDonationSettings()
      if(this.donateSettings.platform){
      this.otherDonationEnabled = true;
    }
    },
    openLink(url) {
      window.open(url, "_blank").focus();
    },
    async donateSwitch() {
      if (this.donateSettings.platform == 'streamlabs') {
        this.donateEnabled = false;
        let deleteRequest = await this.deleteDonationIntegration('streamlabs')
        this.donateSettings = await this.getDonationSettings()
        this.$bvToast.toast("Integration Disabled", {
          title: "Streamlabs Donate Integration",
          variant: "success",
          solid: true,
        });
      } else {
        this.donateEnabled = true;
        const insertQuery = await this.addDonationIntegration('streamlabs', 'username')
        this.donateSettings = await this.getDonationSettings()
        this.$bvToast.toast("Integration Enabled", {
          title: "Streamlabs Donate Integration",
          variant: "success",
          solid: true,
        });
      }
    },
    async unlinkStreamlabs() {
      let requestData = await this.disconnectStreamlabs();
      if(requestData.success == true){
        this.slobsLinked = false;
        this.$bvToast.toast("Streamlabs Integration Unlinked", {
          title: "Streamlabs Integration",
          variant: "success",
          solid: true,
        });
      }
    },
    connectStreamlabs() {
      window.open(this.connectURL, "_blank").focus();
      var linkCheck = setInterval(async () => {
        let result = await this.checkSlobs();
        this.slobsLinked = result.linked;
        if (result.linked == true) {
          clearInterval(linkCheck);
        }
      }, 5000);
    },
  },
};
</script>

<style>
.integrationButton{
  margin-top: 5px;
}
.integrationLabel {
  position: absolute;
  font-size: 20px;
  -ms-transform: translateY(8px);
  transform: translateY(8px);
}
.connectButton {
  float: right;
  display: block;
  justify-content: right;
  align-items: right;
  transform: translateY(140%);
}
.slobs {
  float: left;
  display: block;
  justify-content: right;
  align-items: right;
  padding-top: 35px;
}
</style>