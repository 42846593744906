<template>
  <div>
    <div>
      <h2>Multichat Settings</h2>
      <p>StreamSync&trade; allows you to have all your chat messages in one place.<br>Multi-chat options are available once you configure multi-streaming.</p>
      <!-- 
    <b-alert
      variant="warning"
      show
      class="mt-1"
    >
      <h4 class="alert-heading">
        NOTE:
      </h4>
      <div class="alert-body">
        <span>This feature is not yet live, but you can go ahead and set your values below.</span>
      </div>
    </b-alert>warning -->
    <hr class="mt-3">
      <h4 v-if="(items.length < 1)">Head over to <router-link :to="{ name: 'creator-studio', params: { section: 'integrations' }}">Integrations</router-link> to get started.</h4>
      <b-form
        ref="form"
        :style="{height: trHeight}"
        class="repeater-form"
        @submit.prevent="repeatAgain"
      >

        <!-- Row Loop -->
        <b-row
          v-for="(item, index) in items"
          :id="item.id"
          :key="item.id"
          ref="row"
          class="service-row mt-1"
        >
          <!-- Item Name -->
          <b-col md="2">
            <label for="platform" class="d-block" id="__BVID__260__BV_label_" v-if="(item.platform !== 'YOUTUBE')">Platform</label>
          <span class="platformLogo">
            <img style="margin-top: -15px !important; margin-bottom: -10px !important" v-if="(item.platform == 'youtube')" src="https://assets.brimecdn.com/cdn-cgi/image/width=120,quality=100/youtube_custom_logo.png" height="70">
            <img style="margin-top: 5px !important;" v-if="(item.platform == 'trovo')" src="https://assets.brimecdn.com/cdn-cgi/image/width=120,quality=100/Trovo_Logo.png" height="30">
            <img style="margin-top: -5px !important; margin-bottom: -10px !important" v-if="(item.platform == 'twitch')" src="https://assets.brimecdn.com/cdn-cgi/image/width=120,quality=100/Twitch-Logo.png" width="100">
          </span>
          </b-col>

          <!-- Cost -->
          <b-col md="3">
            <b-form-group
              label-for="streamkey"
            >
            <label for="platform" class="d-block" id="__BVID__260__BV_label_">Channel</label>
              <b-input-group class="input-group-merge">
                <h4 style="margin-top: 8px">
                  <a :href="`https://youtube.com/channel/${item.platform_username}`" target="_blank" v-if="item.platform == 'youtube'">{{item.platform_username}}</a>
                <a :href="`https://twitch.tv/${item.platform_username}`" target="_blank" v-if="item.platform == 'twitch'">{{item.platform_username}}</a>
                <a :href="`https://trovo.live/${item.platform_username}`" target="_blank" v-if="item.platform == 'trovo'">{{item.platform_username}}</a>
                
              
            </h4>
              </b-input-group>
              <label for="platform" class="d-block mt-1 helper" id="__BVID__260__BV_label_" v-if="(item.platform == 'YOUTUBE')">Example: <span style="color: #7367f0">UCdeGyJlj-wognrGxpe5wDCg</span></label>
              <label for="platform" class="d-block mt-1 helper" id="__BVID__260__BV_label_" v-if="(item.platform == 'TWITCH')">Example: <span style="color: #7367f0">geeken</span></label>
              <label for="platform" class="d-block mt-1 helper" id="__BVID__260__BV_label_" v-if="(item.platform == 'TROVO')">Example: <span style="color: #7367f0">geeken</span></label>
            </b-form-group>
          </b-col>

          <!-- Profession -->
          <b-col
            lg="2"
            md="1"
          >
            <b-form-group
              label="Enable/Disable"
              label-for="enableddisable"
            >
              <b-form-checkbox
                class="custom-control-success"
                v-model="chatValues[item.platform.toLowerCase()].enabled"
                name="check-button"
                switch
                inline
                style="margin-top: 7px;"
                @change="toggle(item.platform.toLowerCase())"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <hr>
          </b-col>
        </b-row>

      </b-form>
    </div>
  </div>
</template>

<script>
import {
  BForm, BFormGroup, BFormInput, BRow, BCol, BButton, BFormSelect, BFormCheckbox, BInputGroupAppend, BInputGroup, BAlert
} from 'bootstrap-vue'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormCheckbox,
    BInputGroup,
    BInputGroupAppend,
    BAlert,
    ToastificationContent
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility, heightTransition],
  data() {
    return {
      chatValues: {
          twitch: {
            enabled: false,
            value: '',
          },
          trovo: {
            enabled: false,
            value: '',
          },
          youtube: {
            enabled: false,
            value: '',
          },
      },
      placeholderText: {
          youtube: "Channel ID",
          twitch: "Twitch Username",
          trovo: "Trovo Username",
      },
      selected: null,
      channelData: {},
      chatSettings: [],
      options: [
        { value: 'DLIVE', text: 'DLive' },
        { value: 'FACEBOOK', text: 'Facebook' },
        { value: 'TROVO', text: 'Trovo' },
        { value: 'STEAM', text: 'STEAM' },
        { value: 'TWITCH', text: 'Twitch' },
        { value: 'TWITTER', text: 'Twitter' },
        { value: 'YOUTUBE', text: 'YouTube' },
        { value: 'WASD', text: 'WASD.TV' },
        { value: 'VAUGHN', text: 'VAUGHN.LIVE' },
      ],
      validPlatforms: {
        DLIVE: 'DLive',
        FACEBOOK: 'Facebook',
        STEAM: 'STEAM',
        TROVO: 'Trovo',
        TWITCH: 'Twitch',
        TWITTER: 'Twitter',
        YOUTUBE: 'YouTube',
      },
      items: [],
      nextTodoId: 0,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  async beforeMount() {
    let repubs = await this.getMultichatSettings()
    repubs = repubs.sort((a, b) => a.platform - b.platform)
    console.log(repubs)
    let currentId = 1

    repubs.forEach(repub => {
      const supportedChatPlatforms = ['twitch', 'youtube', 'trovo']
      if (supportedChatPlatforms.indexOf(repub.platform) > -1) {
        this.items.push({
        id: currentId,
        platform: repub.platform,
        platform_username: repub.platform_username,
        enabled: repub.enabled,
      })
      }  
      currentId += 1

      this.nextTickTrAddHeight()
    })

    this.nextTodoId = currentId

    this.repopulate()

    // console.debug(repubs)
    // console.debug(this.items)
  },
  async mounted() {
    window.history.replaceState('', 'Brime Studio - Integrations', '/studio/multichat');
    this.initTrHeight()
    window.addEventListener('resize', this.initTrHeight)
    this.channelData = await this.channelLookupBySlug(this.$auth.user.nickname)
    this.chatSettings = await this.getMultichatSettings()
    console.log(this.chatSettings)
    for (var i in this.chatSettings) {
        this.chatValues[this.chatSettings[i].platform].value = this.chatSettings[i].platform_username
        this.chatValues[this.chatSettings[i].platform].enabled = this.chatSettings[i].enabled
        // this.chatValues[i].enabled = this.chatSettings[i].platform_username
        // console.log(i + ' -> ' + this.chatSettings[i]); // i is the index/key, not the item
    }
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    async updateChatSettings(platform) {
    let result = await this.updateMuiltichat(platform, this.chatValues[platform.toLowerCase()].enabled, this.chatValues[platform.toLowerCase()].value) 
       if (result.success == true){
        this.$bvToast.toast(`Settings have been updated for ${platform}`, {
        title: platform.toUpperCase(),
        variant: 'success',
        solid: true,
      })
    }     
    },
    getUpdatedItems() {
      const items = []

      const elements = Array.from(document.querySelectorAll('.service-row'))

      elements.forEach(e => {
        const ePlatform = e.querySelector('#platform')
        const eStreamkey = e.querySelector('#streamkey')
        const eEnable = e.querySelector('input[type=checkbox]')

        const platform = ePlatform.value
        const streamkey = eStreamkey.value
        const enabled = eEnable.checked

        // Initially unset.
        if (platform.length > 0) {
          items.push({
            id: items.length + 1,
            platform,
            streamkey,
            enabled,
          })
        }
      })

      return items
    },
    async toggle(platform) {
      let result = await this.updateMuiltichat(platform, this.chatValues[platform.toLowerCase()].enabled, this.chatValues[platform.toLowerCase()].value) 
       if (result.success == true){
        this.$bvToast.toast(`Settings have been updated for ${platform}`, {
        title: platform.toUpperCase(),
        variant: 'success',
        solid: true,
      })
    }
        
    },
    repopulate() {
      const platforms = {}

      Object.keys(this.validPlatforms).forEach(key => {
        platforms[key] = false
      })

      this.getUpdatedItems().forEach(item => {
        platforms[item.platform] = true
      })

      Object.keys(this.validPlatforms).forEach(platform => {
        const disabled = platforms[platform]

        const elements = Array.from(document.querySelectorAll(`option[value=${platform}]`))

        // console.debug(`option[value=${platform}]`, elements)

        elements.forEach(e => {
          if (disabled) {
            e.disabled = true
          } else {
            e.removeAttribute('disabled')
          }
        })
      })
    },
    repeatAgain() {
      this.items.push({
        id: this.nextTodoId += this.nextTodoId,
        enabled: true,
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
        this.repopulate()
      })
    },
    removeItem(index) {
      this.items.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
      this.repopulate()
      this.submitAll()
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
    nextTickTrAddHeight() {
      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
.helper{
    font-family: 'Roboto', sans-serif;
    font-size: 13px;
}
.repeater-form {
  transition: .35s height;
}
.platformLogo{

}
</style>
