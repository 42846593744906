<template>
<div>
  <div class="mb-2">
  <h3>Channel Broadcasts</h3>
    <p>
      View past broadcasts on your channel
    </p>
    </div>
  <b-row>
    <b-col
      md="2"
      sm="4"
      class="my-1"
    >
      <b-form-group
        class="mb-0"
      >
        <label class="d-inline-block text-sm-left mr-50">Per page</label>
        <b-form-select
          id="perPageSelect"
          v-model="perPage"
          size="sm"
          :options="pageOptions"
          class="w-50"
        />
      </b-form-group>
    </b-col>
    <b-col
      md="4"
      sm="8"
      class="my-1"
    >
      <b-form-group
        label="Sort"
        label-cols-sm="3"
        label-align-sm="right"
        label-size="sm"
        label-for="sortBySelect"
        class="mb-0"
      >
        <b-input-group size="sm">
          <b-form-select
            id="sortBySelect"
            v-model="sortBy"
            :options="sortOptions"
            class="w-75"
          >
            <template v-slot:first>
              <option value="">
                -- none --
              </option>
            </template>
          </b-form-select>
          <b-form-select
            v-model="sortDesc"
            size="sm"
            :disabled="!sortBy"
            class="w-25"
          >
            <option :value="false">
              Asc
            </option>
            <option :value="true">
              Desc
            </option>
          </b-form-select>
        </b-input-group>
      </b-form-group>
    </b-col>
    <b-col
      md="6"
      class="my-1"
    >
      <b-form-group
        label="Filter"
        label-cols-sm="3"
        label-align-sm="right"
        label-size="sm"
        label-for="filterInput"
        class="mb-0"
      >
        <b-input-group size="sm">
          <b-form-input
            id="filterInput"
            v-model="filter"
            type="search"
            placeholder="Type to Search"
          />
          <b-input-group-append>
            <b-button
               variant="primary"
              :disabled="!filter"
              @click="filter = ''"
            >
              Clear
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
    </b-col>

    <b-col cols="12">
      <b-table
        striped
        hover
        responsive
        :per-page="perPage"
        :current-page="currentPage"
        :items="items"
        :fields="fields"
        primary-key="xid"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
      >
      <template #cell(category)="data">
        {{data.value}}
      </template>
      <template #cell(vod_xid)="data">
      <a :href="`https://brime.tv/videos/${data.value}`" target="_blank" style="font-weight: bold">{{ data.value ? 'Watch VOD' : '' }}</a>
    </template>

        <template #cell(status)="data">
          <b-badge :variant="status[1][data.value]">
            {{ status[0][data.value] }}
          </b-badge>
        </template>
        <template #cell(xid)="data">
          <a :href="`https://brime.tv/videos/${data.value}`" target="_blank" style="font-weight: bold">{{ data.value ? 'View Details' : '' }}</a>
        </template>
        <template #cell(duration)="data">
          {{data.value}}
          <!-- <b-badge v-if="!items[data.index].end_time" variant="primary">
          LIVE
          </b-badge> -->
        </template>
      </b-table>
    </b-col>

    <b-col
      cols="12"
    >
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        align="center"
        size="sm"
        class="my-0"
      />
    </b-col>
  </b-row>
</div>
</template>

<script>
import {
  BTable, BAvatar, BBadge, BRow, BCol, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
  },
  data() {
    return {
      channelData: {},
      perPage: 10,
      pageOptions: [10, 20, 30],
      totalRows: 1,
      currentPage: 1,
      sortBy: 'START_TIME',
      sortDesc: true,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        {
          key: 'title', label: 'Title',
        },
        {
          key: 'categories[0].name', label: 'category',
        },
        { key: 'START_TIME', label: 'Start Time', sortable: true },
        { key: 'duration', label: 'Duration', sortable: true },
        { key: 'vod_xid', label: 'VOD', sortable: true },
        //{ key: 'xid', label: 'Details', sortable: false },
      ],
      items: [],
      status: [{
        1: 'Ended', 2: 'Professional', 3: 'Live', 4: 'Resigned', 5: 'Applied',
      },
      {
        1: 'light-primary', 2: 'light-success', 3: 'light-danger', 4: 'light-warning', 5: 'light-info',
      }],
    }
  },
  computed: {
     statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current      : 'light-primary',
        Professional : 'light-success',
        Rejected     : 'light-danger',
        Resigned     : 'light-warning',
        Applied      : 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  async created(){
    if (this.$auth.user) {
      this.channelData = await this.channelLookupBySlug(this.$auth.user.nickname)
    }
    let broadcasts = await this.getBroadcasts(this.channelData.xid)
    var arrayLength = broadcasts.length;
    for (var i = 0; i < arrayLength; i++) {
    let origStartTimestamp = broadcasts[i].start_time
    broadcasts[i].START_TIME = this.formatDate(broadcasts[i].start_time)
    broadcasts[i].vod = 'N/A'
    if(broadcasts[i].end_time){
      broadcasts[i].END_TIME = this.formatDate(broadcasts[i].end_time)
      broadcasts[i].status = 1
      broadcasts[i].duration = this.convert(broadcasts[i].duration)
    }else {
      broadcasts[i].END_TIME = 'LIVE'
      broadcasts[i].status = 3
      let currentTime = Date.now()
      broadcasts[i].duration = this.convert((currentTime - origStartTimestamp)/1000)
    }
    
    }
    this.items = broadcasts
    this.totalRows = this.items.length
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
    const nextURL = '/studio/broadcasts';
const nextTitle = 'Brime Studio - Broadcasts';
const nextState = { additionalInformation: 'Updated the URL with JS' };

// This will create a new entry in the browser's history, without reloading
window.history.pushState(nextState, nextTitle, nextURL);
  },
  methods: {
    convert(value) {
 const sec = parseInt(value, 10); // convert value to number if it's string
    let hours   = Math.floor(sec / 3600); // get hours
    let minutes = Math.floor((sec - (hours * 3600)) / 60); // get minutes
    let seconds = sec - (hours * 3600) - (minutes * 60); //  get seconds
    // add 0 if value < 10; Example: 2 => 02
    if (hours   < 10) {hours   = "0"+hours;}
    if (minutes < 10) {minutes = "0"+minutes;}
    if (seconds < 10) {seconds = "0"+seconds;}
    return hours+'h '+minutes+'m'; // Return is HH : MM : SS
},
    formatDate(date) {
      const options = {
        year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric',
      }
      return new Date(date).toLocaleDateString(undefined, options)
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>
<style>
[dir] .dark-layout .table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th, [dir] .dark-layout .table:not(.table-dark):not(.table-light) tfoot:not(.thead-dark) th{
background-color: #7367f0
}
</style>
