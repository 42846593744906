<template>
  <b-card border-variant="primary" style="max-width: 450px;">
    <b-form>
      <b-row>
        <b-col cols="12">
          <div class="d-flex align-items-center mb-2">
            <h4 class="mb-0">
              OBS Integrations
            </h4>
          </div>
          <hr class="my-2">
        </b-col>
        <b-col
          cols="12"
          class="mb-1"
        >
          <div class="d-flex">

            <!-- form input -->
            <b-form-group class="mb-0 mr-1">
              <h4 style="margin-top: 0.5rem;">
                Chat - Dock
              </h4>
            </b-form-group>

            <!-- button -->
            <b-button
              v-clipboard:copy="chatWidget"
              v-clipboard:success="onCopy"
              v-clipboard:error="onError"
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="primary"
              size="sm"
            ><feather-icon
              icon="CopyIcon"
            />
              Copy
            </b-button>
          </div>
        </b-col>
        <b-col
          cols="12"
          class="mb-1"
        >
          <div class="d-flex">

            <!-- form input -->
            <b-form-group class="mb-0 mr-1">
              <h4 style="margin-top: 0.5rem;">
                Chat - Stream Overlay
              </h4>
            </b-form-group>

            <!-- button -->
            <b-button
              v-clipboard:copy="chatOverlay"
              v-clipboard:success="onCopy"
              v-clipboard:error="onError"
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="primary"
              size="sm"
            ><feather-icon
              icon="CopyIcon"
            />
              Copy
            </b-button>
          </div>
        </b-col>
        <b-col
          cols="12"
          class="mb-1"
        >
          <div class="d-flex">

            <!-- form input -->
            <b-form-group class="mb-0 mr-1">
              <h4 style="margin-top: 0.5rem;">
                Title/Category - Dock
              </h4>
            </b-form-group>

            <!-- button -->
            <b-button
              v-clipboard:copy="streamWidget"
              v-clipboard:success="onCopy"
              v-clipboard:error="onError"
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="primary"
              size="sm"
            ><feather-icon
              icon="CopyIcon"
            />
              Copy
            </b-button>
          </div>
        </b-col>
        <b-col
          cols="12"
          class="mb-1"
        >
          <div class="d-flex">

            <!-- form input -->
            <b-form-group class="mb-0 mr-1">
              <h4 style="margin-top: 0.5rem;">
                Channel Stats - Dock
              </h4>
            </b-form-group>

            <!-- button -->
            <b-button
              v-clipboard:copy="statsWidget"
              v-clipboard:success="onCopy"
              v-clipboard:error="onError"
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="primary"
              size="sm"
            ><feather-icon
              icon="CopyIcon"
            />
              Copy
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BForm, BRow, BCol, BCard, BButton, BFormGroup, // BCardText,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Clipboard from 'v-clipboard'

export default {
  components: {
    BForm,
    BRow,
    BCol,
    BCard,
    BButton,
    BFormGroup,
    // BCardText,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    Clipboard,
  },
  directives: {
    Ripple,
  },
  methods: {
    onCopy(text) {
      this.$bvToast.toast(`Copied to clipboard`, {
        title: 'Copied!',
        autoHideDelay: 3000,
        variant: 'success',
      })
    },
    onError(err) {
      this.$bvToast.toast(`Error copying: ${err.message}`, {
        title: 'Error!',
        autoHideDelay: 3000,
        variant: 'danger',
      })
    },
  },
  created() {
    this.chatWidget = `https://brime.tv/${this.$auth.user.nickname}/chat`
    this.chatOverlay = `https://brime.tv/${this.$auth.user.nickname}/chat-overlay?ttl=10&animate=true`
    this.streamWidget = `https://brime.tv/${this.$auth.user.nickname}/stream`
    this.statsWidget = `https://brime.tv/${this.$auth.user.nickname}/stats`
  },
}
</script>

<style>

</style>
