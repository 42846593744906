<template>
  <div class="streamTab">
    <b-card style="max-width: 700px" border-variant="primary">
      <!-- form -->
      <h4>Stream Details</h4>
      <hr />
      <b-form>
        <b-row>
          <!-- bio -->
          <b-col cols="12">
            <b-form-group label="Stream Title" label-for="bio-area">
              <b-form-input
                id="title"
                v-model="form.title"
                rows="1"
                placeholder="owning Puppet in Valorant!"
                maxlength="80"
              />
            </b-form-group>
            <b-form-group label="Category" label-for="bio-area2">
              <AutoSuggestAjax @childToParent="onChildClick" />
            </b-form-group>
          </b-col>
          <!--/ bio -->
          <!--/ website -->

          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              size="sm"
              class="mt-1 mr-1"
              @click.prevent="submitForm"
            >
              Save changes
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              size="sm"
              class="mt-1"
              variant="outline-secondary"
              @click.prevent="resetForm"
            >
              Reset
            </b-button>
          </b-col>
        </b-row>
      </b-form></b-card
    >
    <b-card style="max-width: 700px" border-variant="primary">
      <b-form-group label="" label-for="bio-area2">
        <h4>
          <b-avatar
            size="40"
            src="https://assets.brimecdn.com/obslove.png"
            variant="light-primary"
            class="badge-minimal channelAvi"
            style="margin-right: 1em"
          />Brime is included in both Streamlabs and OBS Studio
        </h4>
        <hr />
        We recommend using OBS Studio. You can grab the latest release below. <br /><br />
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          size="sm"
          variant="primary"
          style="margin-top: 5px; margin-right: 10px"
          href="https://obsproject.com/"
          target="_blank"
        >
        Download OBS Studio
        </b-button>
      </b-form-group></b-card
    >
    <b-card style="max-width: 700px" border-variant="primary">
      <h4>Stream Key</h4>
      <hr />
      <b-button
        v-clipboard="(getStreamKey) => streamKey"
        v-clipboard:success="onCopy"
        v-clipboard:error="onError"
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        variant="primary"
        size="sm"
        class="mr-1"
      >
        <feather-icon icon="CopyIcon" class="mr-50 pt-0 pb-0" />
        <span class="align-middle" style="line-height: 14px">Copy</span>
      </b-button>
      <b-button variant="danger" size="sm" @click="resetStreamKey" class="">
        <feather-icon icon="RefreshCwIcon" class="mr-50 pt-0 pb-0" />
        <span class="align-middle" style="line-height: 14px">Reset</span>
      </b-button>
    </b-card>
    <b-card style="max-width: 700px" border-variant="primary"
      ><img
        src="https://assets.brimecdn.com/brime_srt.png"
        width="200"
      />
      <hr />
      <h4 class="mb-2">Interested in testing SRT Streaming?</h4>
      <div mb-1 style="max-width: 300px">
        <b-form-select size="sm" v-model="srtSelected" :options="options" />
      </div>
      <div
        class="mt-1"
        style="
          letter-spacing: 0px !important;
          color: #28c76f;
          white-space: pre-line;
        "
      >
        {{ srtInfo }}
      </div>
      <div
        v-if="srtVideo"
        class="mt-1"
        style="color: #ea5455; font-weight: bold"
      >
        <a href="">{{ srtVideo }}</a>
      </div>
      <div class="mt-1" style="color: #ea5455; font-weight: bold">
        {{ srtError }}
      </div>
      <b-button
        v-clipboard="generateSRT"
        v-clipboard:success="onSrt"
        v-clipboard:error="onError"
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        variant="primary"
        size="sm"
        class="mr-1 mt-1"
      >
        <feather-icon icon="ServerIcon" class="mr-50 pt-0 pb-0" />
        <span class="align-middle" style="line-height: 14px"
          >Generate SRT URL & Copy</span
        >
      </b-button>
    </b-card>
    <b-card style="max-width: 700px" border-variant="primary" class="mb-5">
      <h4 class="mb-1">Need a server URL for custom streaming?</h4>
      <hr />
      <b-button
        @click="openIngests"
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        variant="primary"
        size="sm"
        class="mr-1"
      >
        <feather-icon icon="ServerIcon" class="mr-50 pt-0 pb-0" />
        <span class="align-middle" style="line-height: 14px"
          >View our ingest servers</span
        >
      </b-button>
    </b-card>
  </div>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BRow,
  BCol,
  BCard,
  BFormInput,
  BAvatar,
  BFormSelect,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import Vue from "vue";
// eslint-disable-next-line import/no-extraneous-dependencies
import "cleave.js/dist/addons/cleave-phone.us";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import Clipboard from "v-clipboard";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AutoSuggestAjax from "./Test.vue";

Vue.use(Clipboard);

export default {
  components: {
    BFormInput,
    BButton,
    BForm,
    BFormGroup,
    BRow,
    BCol,
    BCard,
    AutoSuggestAjax,
    BAvatar,
    BFormSelect,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    Clipboard,
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  props: {
    informationData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      srtInfo: "",
      srtGenerated: "",
      srtError: null,
      fromChild: "",
      datasuggest: [],
      srtVideo: null,
      filteredOptions: [],
      limit: 10,
      selected: null,
      onboardIframe: "",
      form: {
        title: "",
        category: "",
        enableVods: true,
      },
      srtSelected: null,
      options: [
        { value: null, text: "Please select an ingest region" },
        { value: "ingest-us-ashburn.brime.tv", text: "USA - (ASHBURN, VA)" },
        { value: "ingest-us-phoenix.brime.tv", text: "USA - (PHOENIX, AZ)" },
        { value: "ingest-us-sanjose.brime.tv", text: "USA - (SAN JOSE, CA)" },
        { value: "ingest-apac-sydney.brime.tv", text: "Austrailia - (SYDNEY)" },
        { value: "ingest-sa-saopaulo.brime.tv", text: "Brazil - (SÃO PAULO)" },
        { value: "ingest-ca-montreal.brime.tv", text: "Canada - (MONTREAL)" },
        { value: "ingest-eu-frankfurt.brime.tv", text: "Germany - (FRANKFURT)" },
        { value: "ingest-apac-mumbai.brime.tv", text: "India - (MUMBAI)" },
        { value: "ingest-apac-tokyo.brime.tv", text: "Japan - (TOKYO)" },
        {
          value: "ingest-eu-amsterdam.brime.tv",
          text: "Netherlands - (AMSTERDAM)",
        },
        { value: "ingest-ru-moscow.brime.tv", text: "Russia - (MOSCOW)" },
        { value: "ingest-apac-seoul.brime.tv", text: "South Korea - (SEOUL)" },
        {
          value: "ingest-eu-switzerland.brime.tv",
          text: "Switzerland - (ZURICH)",
        },
        { value: "ingest-uae-dubai.brime.tv", text: "UAE - (DUBAI)" },
        {
          value: "ingest-eu-london.brime.tv",
          text: "United Kingdom - (LONDON)",
        },
      ],
      stream: {
        server: "Check #dev-updates in the Discord",
        streamKey: "",
      },
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  mounted(){
    history.replaceState('data to be passed', 'Brime Studio - Stream', '/studio/stream-settings');
  },
  async created() {
    // this.$http.get('').then(res => { this.options = res.data.streams })
    if (this.$auth.user) {
      this.channelData = await this.channelLookupBySlug(
        this.$auth.user.nickname
      );
      this.form.title = this.channelData.stream.title;
      this.form.category = this.channelData.stream.category.name;
      this.streamKey = await this.getStreamKey();
    }

    // window.brime.getChannel()
    //   .then(async response => {
    //     const userData = await window.brime.getUser()
    //     const {
    //       title, category, vodsEnabled,
    //     } = response

    //     this.form.title = title
    //     // eslint-disable-next-line no-underscore-dangle
    //     this.form.category = category.name
    //     this.form.enableVods = vodsEnabled
    //     // eslint-disable-next-line no-underscore-dangle
    //     this.stream.streamKey = `${response._id}?auth=${userData.streamKey}`
    //   })
  },
  /* eslint-disable */
  methods: {
    generateSRT() {
      if (this.srtSelected == null) {
        this.srtInfo = null;
        this.srtError = "Please select an ingest region.";
        return;
      } else {
        this.srtError = null;
        // this.srtVideo = 'Video about Brime & SRT'
        this.srtInfo =
          "SRT URL has been generated and copied to your clipboard. \n Paste this URL into OBS under the server field. Leave the key field blank";
      }
      this.srtGenerated = `srt://${this.srtSelected}:1969?streamid=#!::h=live/${this.streamKey},m=publish`;
      return this.srtGenerated;
    },
    openIngests() {
      window.open("https://network.brime.tv/");
    },
    async resetStreamKey() {
      let requestRefresh = await this.refreshStreamKey();
      this.streamKey = requestRefresh.stream_key;
      this.$bvToast.toast("Stream Key has been reset", {
        title: "Stream Key Reset",
        variant: "success",
        solid: true,
      });
    },
    onCopy() {
      this.$bvToast.toast("Stream key copied to clipboard.", {
        title: "Stream Key",
        variant: "success",
        solid: true,
      });
    },
    onSrt() {
      //     this.$bvToast.toast("SRT URL Generated and copied to clipboard.", {
      // title: 'SRT Geenerated',
      //  variant: 'success',
      //  solid: true,
      // })
    },
    onError() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Failed to copy texts!",
          icon: "BellIcon",
        },
      });
    },
    onChildClick(value) {
      this.fromChild = value;
    },
    makeToast(message) {
      this.$bvToast.toast(message, {
        title: "Error uploading image.",
        variant: "danger",
        solid: true,
      });
    },
    async submitForm() {
      let streamUpdate = await this.channelUpdateStream(
        this.form.title,
        this.fromChild
      );
      if (streamUpdate.success == true) {
        this.$bvToast.toast("Title and Category updated successfully.", {
          title: "Success",
          variant: "success",
          solid: true,
        });
      } else {
        this.$bvToast.toast(message, {
          title: "Error.",
          variant: "danger",
          solid: true,
        });
      }
    },
    resetForm() {
      this.form = "";
    },
  },
};
</script>
<style>
.center {
  margin: auto;
  width: 50%;
}
[dir] .dark-layout .card {
  background-color: #161d31;
}
[dir] .dark-layout input.form-control,
[dir] .dark-layout .custom-file-label,
[dir] .dark-layout textarea.form-control {
  background-color: #1f2a4b;
}
[dir] .dark-layout select.form-control,
[dir] .dark-layout .custom-select {
  background-color: #1f2a4b;
}
</style>
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/pages/page-auth.scss";
</style>
