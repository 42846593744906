<template>
  <div>
    <div>
      <h2>Multistream Settings</h2>
      <p>StreamSync&trade; allows you to have your Brime stream pushed to multiple platforms at the same time.
      <br>You can enable and disable on the fly without ever having to restart your stream.
      <br>This feature is provided for free to every creator on Brime.</p>
      <hr class="mt-3">
      <h4 v-if="(items.length < 1)">Head over to <router-link :to="{ name: 'creator-studio', params: { section: 'integrations' }}">Integrations</router-link> to get started.</h4>
      <b-form
        ref="form"
        class="repeater-form"
        @submit.prevent="repeatAgain"
      >

        <!-- Row Loop -->
        <b-row
          v-for="(item, index) in items"
          :id="item.id"
          :key="item.id"
          ref="row"
          class="service-row"
        >
          <!-- Item Name -->
          <b-col md="2">
            <b-form-group
            label="Platform"
              label-for="platform"
            >
            <span class="platformLogo">
              <img style="margin-top: -5px !important; margin-bottom: -10px !important" v-if="(item.platform == 'youtube')" src="https://assets.brimecdn.com/cdn-cgi/image/width=120,quality=100/youtube_custom_logo.png" height="60">
              <img style="margin-top: 5px !important;" v-if="(item.platform == 'trovo')" src="https://assets.brimecdn.com/cdn-cgi/image/width=120,quality=100/Trovo_Logo.png" height="30">
              <img style="margin-top: -5px !important; margin-bottom: -10px !important" v-if="(item.platform == 'twitch')" src="https://assets.brimecdn.com/cdn-cgi/image/width=120,quality=100/Twitch-Logo.png" width="100">
              </span>
            </b-form-group>
          </b-col>

          <!-- Cost -->
          <b-col md="3">
            <b-form-group
              label="Stream Key"
              label-for="streamkey"
            >
              <h4 class="mt-1" v-if="item.platform == 'twitch' || item.platform == 'trovo' || item.platform == 'youtube'">Managed via <RouterLink :to="{ name: 'creator-studio', params: { section: 'integrations' }}">Integrations</RouterLink></h4>
              <b-input-group class="input-group-merge" v-if="item.platform !== 'twitch' && item.platform !== 'trovo' && item.platform !== 'youtube'">
                <b-form-input
                  id="streamkey"
                  :value="item.streamkey"
                  class="form-control-merge"
                  :type="passwordFieldType"
                  placeholder="Paste stream key here"
                  @change="submitAll"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    class="cursor-pointer"
                    :icon="passwordToggleIcon"
                    @click="togglePasswordVisibility"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>

          <!-- Profession -->
          <b-col
            lg="2"
            md="1"
          >
            <b-form-group
              label="Enable/Disable"
              label-for="enableddisable"
            >
              <b-form-checkbox
                class="custom-control-success"
                :checked="item.enabled"
                name="check-button"
                switch
                inline
                style="margin-top: 7px;"
                @change="toggleUpdate(item.platform, item.enabled)"
              />
            </b-form-group>
          </b-col>

          <!-- Remove Button -->
          <!-- <b-col
            lg="2"
            md="3"
            class="mb-50"
          >
            <b-button
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              v-bind:title="(item.platform == 'twitch' || item.platform == 'trovo') ? 'Managed via Integration' : 'Delete'"
              variant="outline-danger"
              class="mt-0 mt-md-2"
              @click="removeItem(index)"
              v-bind:disabled="item.platform == 'twitch' || item.platform == 'trovo'"
            >
              <feather-icon
                icon="Trash2Icon"
              />
            </b-button>
          </b-col> -->
          <b-col cols="12">
            <hr class="">
          </b-col>
        </b-row>

      </b-form>
    </div>
    <!-- <b-button
      title="temporarily disabled"
      disabled
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      class="repeat-button mt-3"
      click="repeatAgain"
      
    >
      <feather-icon
        icon="PlusIcon"
        class="mr-25"
      />
      <span>Add Stream Destination</span>
    </b-button> -->
  </div>
</template>

<script>
import {
  BForm, BFormGroup, BFormInput, BRow, BCol, BButton, BFormSelect, BFormCheckbox, BInputGroupAppend, BInputGroup,
} from 'bootstrap-vue'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'

export default {
  components: {
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormCheckbox,
    BInputGroup,
    BInputGroupAppend,
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility, heightTransition],
  data() {
    return {
      selected: null,
      options: [
        { value: 'DLIVE', text: 'DLive' },
        { value: 'FACEBOOK', text: 'Facebook' },
        { value: 'trovo', text: 'trovo' },
        { value: 'STEAM', text: 'STEAM' },
        { value: 'twitch', text: 'twitch' },
        { value: 'TWITTER', text: 'Twitter' },
        { value: 'YOUTUBE', text: 'YouTube' },
        { value: 'WASD', text: 'WASD.TV' },
        { value: 'VAUGHN', text: 'VAUGHN.LIVE' },
      ],
      validPlatforms: {
        DLIVE: 'DLive',
        FACEBOOK: 'Facebook',
        STEAM: 'STEAM',
        trovo: 'trovo',
        twitch: 'twitch',
        TWITTER: 'Twitter',
        YOUTUBE: 'YouTube',
      },
      items: [],
      nextTodoId: 0,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  async beforeMount() {
    function dynamicSort(property) {
    var sortOrder = -1;
    if(property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }
    return function (a,b) {
        /* next line works with strings and numbers, 
         * and you may want to customize it to your needs
         */
        var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
        return result * sortOrder;
    }
}
    let repubs_lookup = await this.getRepubs()
    const repubs = repubs_lookup.sort(dynamicSort('platform'))
    console.log(repubs)
    let currentId = 1

    repubs.forEach(repub => {
      this.items.push({
        id: currentId,
        platform: repub.platform,
        streamkey: repub.stream_key,
        enabled: repub.enabled,
      })

      currentId += 1

      this.nextTickTrAddHeight()
    })

    this.nextTodoId = currentId

    this.repopulate()

    // console.debug(repubs)
    // console.debug(this.items)
  },
  mounted() {
    this.initTrHeight()
    window.addEventListener('resize', this.initTrHeight)
    history.replaceState('data to be passed', 'Brime Studio - Stream', '/studio/multistreaming');
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    getUpdatedItems() {
      const items = []

      const elements = Array.from(document.querySelectorAll('.service-row'))

      elements.forEach(e => {
        const ePlatform = e.querySelector('#platform')
        const eStreamkey = e.querySelector('#streamkey')
        const eEnable = e.querySelector('input[type=checkbox]')

        const platform = ePlatform.value
        const streamkey = eStreamkey.value
        const enabled = eEnable.checked

        // Initially unset.
        if (platform.length > 0) {
          items.push({
            id: items.length + 1,
            platform,
            streamkey,
            enabled,
          })
        }
      })

      //document.querySelector('.repeat-button').disabled = (elements.length >= 6)

      return items
    },
    async submitAll() {
      const toDelete = []
      const toCreate = []
      const toUpdate = []

      const existingRepubs = {}
      const items = {};

      (await this.getRepubs()).forEach(repub => {
        existingRepubs[repub.platform] = repub
      })

      this.getUpdatedItems().forEach(item => {
        items[item.platform] = item
      })

      Object.keys(items).forEach(platform => {
        if (existingRepubs[platform]) {
          toUpdate.push(platform)
        } else {
          toCreate.push(platform)
        }
      })

      Object.keys(existingRepubs).forEach(platform => {
        if (!items[platform]) {
          toDelete.push(platform)
        }
      })

      const apiPromises = []

      toDelete.forEach(platform => {
        apiPromises.push(this.deleteRepub(platform))
      })

      toCreate.forEach(name => {
        const platform = items[name]

        apiPromises.push(this.createRepub(name, platform.streamkey))
      })

      toUpdate.forEach(name => {
        const platform = items[name]

        apiPromises.push(this.updateRepub(name, platform.enabled, platform.streamkey))
      })

      // console.debug('')
      // console.debug('form', items)
      // console.debug('existing', existingRepubs)
      // console.debug('Calculated delta:')
      // console.debug('toDelete', toDelete)
      // console.debug('toCreate', toCreate)
      // console.debug('toUpdate', toUpdate)

      Promise.all(apiPromises).then(() => {
        this.$bvToast.toast('Multistream settings updated.', {
          title: 'Success',
          variant: 'success',
          solid: true,
        })
      })
        .catch(() => {
          this.$bvToast.toast('An error occured while updating multistream settings, please contact support.', {
            title: 'Error.',
            variant: 'danger',
            solid: true,
          })
        })
    },
    async toggleUpdate(platform, enabled) {
      const repubs = await this.getRepubs()
      let platform_meta = repubs.find(repub => repub.platform === platform);
      const update = await this.updateRepub(platform, !platform_meta.enabled)
      if(update.success) {
        this.$bvToast.toast('Multistream settings updated.', {
          title: 'Success',
          variant: 'success',
          solid: true,
        })
      }
      if(update.error) {
        this.$bvToast.toast('Multistream settings updated.', {
          title: 'Success',
          variant: 'success',
          solid: true,
        })
      }
    },
    repopulate() {
      const platforms = {}

      Object.keys(this.validPlatforms).forEach(key => {
        platforms[key] = false
      })

      this.getUpdatedItems().forEach(item => {
        platforms[item.platform] = true
      })

      Object.keys(this.validPlatforms).forEach(platform => {
        const disabled = platforms[platform]

        const elements = Array.from(document.querySelectorAll(`option[value=${platform}]`))

        // console.debug(`option[value=${platform}]`, elements)

        elements.forEach(e => {
          if (disabled) {
            e.disabled = true
          } else {
            e.removeAttribute('disabled')
          }
        })
      })
    },
    repeatAgain() {
      this.items.push({
        id: this.nextTodoId += this.nextTodoId,
        enabled: true,
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
        this.repopulate()
      })
    },
    removeItem(index) {
      this.items.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
      this.repopulate()
      this.submitAll()
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
    nextTickTrAddHeight() {
      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.repeater-form {
  transition: .35s height;
}
</style>
