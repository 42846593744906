<template>
  <div>
    <h3>ChatBot Settings</h3>
    <p>
      These settings allow you to create chat commands natively within Brime.
    </p>
    <hr class="" />
    <!-- basic -->
    <b-row>
      <b-form-checkbox
        v-model="botName.custom"
        @change="toggleBotName()"
        class="mr-1 ml-1"
        checked="true"
        name="check-button"
        switch
        inline
      >
        Custom Chat Bot Name?
      </b-form-checkbox>

      <b-col md="3">
        <b-form-group
          v-if="botName.custom"
          label="Custom Bot Name"
          label-for="basicInput"
        >
          <b-form-input
            id="basicInput"
            placeholder="GeekBot"
            v-model="botName.name"
            @change="updateBot(botName.name)"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <hr class="" />
    <div>
      <b-form
        ref="form"
        autocomplete="off"
        :style="{ height: trHeight }"
        class="repeater-form"
        @submit.prevent="repeateAgain"
      >
        <!-- Row Loop -->
        <b-row
          v-for="(item, index) in items"
          :id="item.id"
          :key="item.id"
          ref="row"
        >
          <!-- Command -->
          <b-col md="2">
            <b-form-group label="Command" label-for="command">
              <b-form-input
                id="command"
                type="text"
                autocomplete="off"
                placeholder="!command"
                v-model="item.command"
                @change="commandFieldUpdate(item)"
              />
            </b-form-group>
          </b-col>

          <!-- Item Name -->
          <b-col md="7">
            <b-form-group label="Command Response" label-for="response">
              <b-form-input
                id="response"
                type="text"
                autocomplete="off"
                placeholder="This is the response to the command"
                v-model="item.response"
                @change="commandUpdate(item)"
              />
            </b-form-group>
          </b-col>

          <!-- Remove Button -->
          <b-col lg="2" md="3" class="mb-50">
            <b-button
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              variant="outline-danger"
              class="mt-0 mt-md-2"
              @click="removeItem(index, item.xid)"
            >
              <feather-icon icon="Trash2Icon" />
            </b-button>
          </b-col>
          <b-col cols="12">
            <hr />
          </b-col>
        </b-row>
      </b-form>
    </div>
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      @click="repeateAgain"
      class="mb-3 ml-1"
    >
      <feather-icon icon="PlusIcon" class="mr-25" />
      <span>New Command</span>
    </b-button>
  </div>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BButton,
  BCard,
  BCardBody,
  BFormCheckbox,
} from "bootstrap-vue";
import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BCard,
    BCardBody,
    BFormCheckbox,
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      botName: "",
      items: [],
      nextTodoId: 2,
    };
  },
  mounted() {
    this.initTrHeight();
  },
  async created() {
    if (this.$auth.user) {
      this.channelData = await this.channelLookupBySlug(
        this.$auth.user.nickname
      );
      const botCheck = await this.chatBotCheck(this.channelData.xid);
      const commands = await this.chatBotCommandsLookup(
        this.channelData.xid
      );
      console.log(commands)
      let currentId = 1;
      commands.forEach((commandItem) => {
        this.items.push({
          id: currentId,
          xid: commandItem.xid,
          command: commandItem.command,
          response: commandItem.response,
          inDB: true,
        });
        currentId += 1;

        this.nextTickTrAddHeight();
      });
      this.botName = botCheck;
      if (this.botName.name == "BrimeBot") {
        this.botName.name = "";
      }
    }
    window.addEventListener("resize", this.initTrHeight);
  },
  destroyed() {
    window.removeEventListener("resize", this.initTrHeight);
  },
  methods: {
    commandFieldUpdate(item) {
      if(item.response){
        this.commandUpdate(item);
      }
    },
    commandUpdate(item) {
      if (item.inDB) {
        this.updateChatCommand(item);
      } else {
        this.putChatCommand(item);
      }
    },
    async putChatCommand(item) {
      const putCommandRequest = await this.putCommand(
        item.command,
        item.response,
        this.channelData.xid
      );
      if (putCommandRequest.success) {
        for (const obj of this.items) {
          if (obj.id === item.id) {
            obj.xid = putCommandRequest.command_xid;
            obj.inDB = true;
            break;
          }
        }
        // item[item.id] = {
        //   xid: putCommandRequest.command_xid,
        //   inDB: true
        // }
        this.$bvToast.toast(`Chat Command Added`, {
          title: "Success",
          variant: "success",
          solid: true,
        });
      } else {
        this.$bvToast.toast(`Unable to add chat command`, {
          title: "ERROR",
          variant: "danger",
          solid: true,
        });
      }
    },
    async updateChatCommand(item) {
      const updateCommandRequest = await this.updateCommand(
        item.xid,
        item.command,
        item.response
      );
      if (updateCommandRequest.result == "success") {
        this.$bvToast.toast(`Chat Command Updated`, {
          title: "Success",
          variant: "success",
          solid: true,
        });
      } else {
        this.$bvToast.toast(`Unable to update chat command`, {
          title: "ERROR",
          variant: "danger",
          solid: true,
        });
      }
    },
    async updateBot(name) {
      const updateRequest = await this.chatBotUpdate(name);
      if (updateRequest.result == "success") {
        this.$bvToast.toast(`Chat Bot Name Updated`, {
          title: "Success",
          variant: "success",
          solid: true,
        });
      }
    },
    async deleteBot() {
      const deleteRequest = await this.chatBotDelete();
      if (updateRequest.result == "success") {
        this.$bvToast.toast(`Chat Bot Name Deleted`, {
          title: "Success",
          variant: "success",
          solid: true,
        });
      }
    },
    toggleBotName() {
      if (this.botName.custom) {
        return;
      } else {
        this.deleteBot();
      }
    },
    nextTickTrAddHeight() {
      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight);
      });
    },
    repeateAgain() {
      this.items.push({
        id: (this.nextTodoId += this.nextTodoId),
      });

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight);
      });
    },
    async removeItem(index, xid) {
      const deleteRequest = await this.deleteBotCommand(xid, this.channelData.xid);
      if (deleteRequest.success) {
        this.$bvToast.toast(`Chat Command Deleted`, {
          title: "Success",
          variant: "success",
          solid: true,
        });
      } else {
        this.$bvToast.toast(`Unable to delete chat command`, {
          title: "ERROR",
          variant: "danger",
          solid: true,
        });
      }
      this.items.splice(index, 1);
      this.trTrimHeight(this.$refs.row[0].offsetHeight);
    },
    initTrHeight() {
      this.trSetHeight(null);
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}
</style>
