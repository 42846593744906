<template>
  <div>
    <h2 class="mb-3">
      Monetization & Payouts
    </h2>
    <b-button
      variant="primary"
      class="mb-2"
      @click="openDashboard"
    >
      Payout Settings
    </b-button><br>
    <b-button
      variant="primary"
      @click="openPayoutHistory"
    >
      Payout History&nbsp;&nbsp;
    </b-button>
  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue'

export default {
  components: {
    BButton,
  },
  data() {
    return {
      iframes: {},
    }
  },
  async created() {
    this.iframes = await this.getPayoutLinks()
  },
  methods: {
    openDashboard() {
      window.open(this.iframes.payout_iframes.dashboard)
    },
    openPayoutHistory() {
      window.open(this.iframes.payout_iframes.payments)
    },
  },
}
</script>

<style></style>
