<template>
  <div>
  <div class="mb-2">
  <h3>Channel Settings</h3>
    <p>
      Control the look and feel of your channel
    </p>
    </div>
      <b-card
      style="max-width: 35em;"
      border-variant="primary"
      v-if="about"
    >
    <h3 style="color: #ffffff">
          Channel About
        </h3>
        <hr class="">
    <b-form-textarea
      id="textarea-default"
      placeholder="Let viewers know more about you"
      rows="3"
    />
  </b-card>
    <b-card
      style="max-width: 35em;"
      border-variant="primary"
    >
      <b-form>
        <h3 style="color: #ffffff">
          Channel Settings
        </h3>
        <hr class="">
        <b-form-checkbox
      v-model="vodsEnabled"
      name="check-button"
      switch
      inline
      @change="updateVodsEnabled()"
    >
      Enable Vod Recording
    </b-form-checkbox><br>
    <b-form-checkbox
      v-model="hideViewercount"
      class="mt-1"
      name="check-button"
      switch
      inline
      disabled
      @change="updateVodsEnabled()"
    >
      Hide Viewer Count
    </b-form-checkbox>
      </b-form>
    </b-card>
    <b-card
      style="max-width: 35em;"
      border-variant="primary"
    >
      <b-form>
        <h3 style="color: #ffffff">
          Channel Offline Image
        </h3>
        <hr class="">
        <b-row>
          <center>
            <b-col
              sm="6"
              lg="12"
            >
              <img
                v-bind:src="offlineImage"
                style="max-width: 500px"
                class="mb-1 offlineImage"
              >
              <b-form-file
                id="offlineImageUpload"
                ref="offlineImageUpload"
                style="max-width: 30em;"
                accept="image/jpeg, image/png, image/gif"
                @change="updateAvatarImage"
              />
            </b-col><p class="mt-1">
              Max File Size: 10mb | GIFs supported
            </p></center>
        <!--/ buttons -->
        </b-row>
      </b-form>
    </b-card>
  </div>
</template>

<script>
import {
  BForm, BRow, BCol, BCard, BFormFile, BFormCheckbox, BFormTextarea // BAvatar, // BCardText,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BFormTextarea,
    BForm,
    BRow,
    BCol,
    BCard,
    BFormFile,
    BFormCheckbox,
    // BAvatar,
    // BCardText,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      hideViewercount: false,
      about: false,
      offlineImage: 'https://assets.brimecdn.com/channels/offline_image',
      vodsEnabled: null,
    }
  },
  async created() {
    if (this.$auth.user) {
      this.channelData = await this.channelLookupBySlug(this.$auth.user.nickname)
      this.vodsEnabled = this.channelData.vods_enabled
      this.offlineImage = `https://assets.brimecdn.com/cdn-cgi/image/width=1280,height=720,quality=100,format=auto/channels/${this.channelData.xid}/offline_image`
    }
  },
  methods: {
    async updateVodsEnabled(){
      const result = await this.toggleVods(this.vodsEnabled)
      if (!result.error) {
        this.$bvToast.toast('Vod Setting Updated', {
          title: 'Success',
          variant: 'success',
          solid: true,
        })
      } else {
        this.$bvToast.toast('Error', {
          title: 'Error.',
          variant: 'danger',
          solid: true,
        })
      }
    },
    async updateAvatarImage() {
      const result = await this.uploadOfflineImage(this.$refs.offlineImageUpload.$refs.input.files[0])
      if (!result.error) {
        this.$bvToast.toast('Avatar Image Updated', {
          title: 'Success',
          variant: 'success',
          solid: true,
        })
        this.offlineImage = `${result.url}?date=${Date.now()}`
      } else {
        this.$bvToast.toast('Error', {
          title: 'Error.',
          variant: 'danger',
          solid: true,
        })
      }
    },
  },
}
</script>

<style>
.offlineImage{
width: 100%
}
</style>
<style scoped>
.card{
background-color:  #161d31
}
[dir] .dark-layout input.form-control, [dir] .dark-layout .custom-file-label, [dir] .dark-layout textarea.form-control{
background-color: #172038
}
</style>