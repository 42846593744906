<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0 ml-md-1"
    pills
    nav-wrapper-class="col-md-2 mr-md-4 col-12"
    nav-class="nav-left"
  >

    <!-- info -->
    <b-tab
      lazy
      v-bind:active="activeCheck('stream-settings')"
    >

      <!-- title -->
      <template #title>
        <feather-icon
          icon="RadioIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Stream</span>
      </template>

      <stream />
    </b-tab>
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="VideoIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Channel</span>
      </template>
      <ChannelSettings />
    </b-tab>

    <b-tab lazy>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="UserPlusIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Followers</span>
      </template>
      <Followers />
    </b-tab>
   <!-- <b-tab disabled>
      
      <template #title>
        <feather-icon
          icon="ChevronsUpIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Achievements</span>
      </template>
    </b-tab>-->
      <b-tab lazy disabled>
      <!-- title -->
      <template #title>
        <feather-icon
          icon="ShieldIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Emotes</span>
      </template>

      <Emotes />
    </b-tab>
    <b-tab lazy>
      <!-- title -->
      <template #title>
        <feather-icon
          icon="ShieldIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Chat Settings</span>
      </template>

      <ChatSettings />
    </b-tab>
    <b-tab lazy>
      <!-- title -->
      <template #title>
        <feather-icon
          icon="WindIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">ChatBot</span>
      </template>

      <ChatBot />
    </b-tab>
    <!--
    <b-tab disabled>
      
      <template #title>
        <feather-icon
          icon="FilmIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Media Manager</span>
      </template>
    </b-tab>--->
    <b-tab lazy disabled>
      <!-- title -->
      <template #title>
        <feather-icon
          icon="ShieldOffIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Moderation</span>
      </template>
      <Integrations />
    </b-tab>
    <b-tab 
    lazy
    v-bind:active="activeCheck('broadcasts')"
    >
      <!-- title -->
      <template #title>
        <feather-icon
          icon="TvIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Broadcasts</span>
      </template>

      <broadcasts />
    </b-tab>
    <b-tab
      v-if="channelData.channel && channelData.channel.status =='ELITE'"
      lazy
    >
      <!-- title -->
      <template #title>
        <feather-icon
          icon="CreditCardIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Monetization</span>
      </template>
      <monetization />
    </b-tab>
    <b-tab lazy v-bind:active="activeCheck('multistreaming')">
      <!-- title -->
      <template #title>
        <feather-icon
          icon="Share2Icon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Multistreaming</span>
      </template>
      <multistreaming />
    </b-tab>
    <b-tab lazy v-bind:active="activeCheck('multichat')">
      <!-- title -->
      <template #title>
        <feather-icon
          icon="MessageSquareIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Multichat</span>
      </template>
      <multichat />
    </b-tab>
    <b-tab lazy v-bind:active="activeCheck('integrations')">
      <!-- title -->
      <template #title>
        <feather-icon
          icon="LinkIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Integrations</span>
      </template>
      <Integrations />
    </b-tab>
    <!-- <b-tab lazy>
      <template #title>
      <span class="discord">
       <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" style=" fill:#ffffff;"><path d="M19.54 0c1.356 0 2.46 1.104 2.46 2.472v21.528l-2.58-2.28-1.452-1.344-1.536-1.428.636 2.22h-13.608c-1.356 0-2.46-1.104-2.46-2.472v-16.224c0-1.368 1.104-2.472 2.46-2.472h16.08zm-4.632 15.672c2.652-.084 3.672-1.824 3.672-1.824 0-3.864-1.728-6.996-1.728-6.996-1.728-1.296-3.372-1.26-3.372-1.26l-.168.192c2.04.624 2.988 1.524 2.988 1.524-1.248-.684-2.472-1.02-3.612-1.152-.864-.096-1.692-.072-2.424.024l-.204.024c-.42.036-1.44.192-2.724.756-.444.204-.708.348-.708.348s.996-.948 3.156-1.572l-.12-.144s-1.644-.036-3.372 1.26c0 0-1.728 3.132-1.728 6.996 0 0 1.008 1.74 3.66 1.824 0 0 .444-.54.804-.996-1.524-.456-2.1-1.416-2.1-1.416l.336.204.048.036.047.027.014.006.047.027c.3.168.6.3.876.408.492.192 1.08.384 1.764.516.9.168 1.956.228 3.108.012.564-.096 1.14-.264 1.74-.516.42-.156.888-.384 1.38-.708 0 0-.6.984-2.172 1.428.36.456.792.972.792.972zm-5.58-5.604c-.684 0-1.224.6-1.224 1.332 0 .732.552 1.332 1.224 1.332.684 0 1.224-.6 1.224-1.332.012-.732-.54-1.332-1.224-1.332zm4.38 0c-.684 0-1.224.6-1.224 1.332 0 .732.552 1.332 1.224 1.332.684 0 1.224-.6 1.224-1.332 0-.732-.54-1.332-1.224-1.332z"/></svg>
        </span><span class="font-weight-bold"> Discord</span>
      </template>
      <Integrations />
    </b-tab> -->
    <b-tab>
    
      <!-- title -->
      <template #title>
        <feather-icon
          icon="ToolIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Widgets &amp; Docks</span>
      </template>
      <stream-tools />
    </b-tab>
  </b-tabs>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
// import AccountProfile from './AccountProfile.vue'
// import AccountSettingSocial from './AccountSettingSocial.vue'
// import AccountChannelSettings from './AccountChannelSettings.vue'
// import GettingStarted from './GettingStarted.vue'
// import Monetization from './Monetization.vue'
// import Multistreaming from './Multistreaming.vue'
import Broadcasts from './Comps/Broadcasts.vue'
import ChatSettings from './Comps/ChatSettings.vue'
import Stream from './Comps/Stream.vue'
import Monetization from './Comps/Monetization.vue'
import StreamTools from './Comps/StreamTools.vue'
import Multistreaming from './Comps/Multistreaming.vue'
import ChannelSettings from './Comps/ChannelSettings.vue'
import Integrations from './Comps/Integrations.vue'
import Multichat from './Comps/Multichat.vue'
import ChatBot from './Comps/ChatBot.vue'
import Emotes from './Comps/Emotes.vue'
import Followers from './Comps/Followers.vue'

export default {
  props: ['section'],
  components: {
    BTabs,
    BTab,
    // AccountProfile,
    // AccountSettingInformation,
    // AccountSettingSocial,
    // AccountChannelSettings,
    // GettingStarted,
    // Monetization,
    // Multistreaming,
    // Emotes,
    ChatSettings,
    Broadcasts,
    Stream,
    Monetization,
    StreamTools,
    Multistreaming,
    ChannelSettings,
    Integrations,
    Multichat,
    ChatBot,
    Emotes,
    Followers
  },
  data() {
    return {
      creatorStatus: '',
      userData: '',
      monetized: false,
      options: {},
      channelData: {},
    }
  },
  // eslint-disable-next-line no-empty-function
  async mounted() {
  },
  async created() {
    this.tab = this.$route.hash
    this.channelData = await this.channelLookupBySlug(this.$auth.user.nickname)
    // this.userData = await window.brime.getUser()
    // if (await this.userData.creatorStatus === 'ELITE') {
    //   this.monetized = true
    // }
  },
  methods: {
     activeCheck(tab){
      let page = ''
      if(this.$route.path === '/studio'){
        page = 'stream-settings'
      } else {page = this.$route.path.replace('/studio/', '')}
      if(tab == page) return true
     }
  },
}
</script>
<style scoped>
.discord svg {
   transform: scale(0.9);
}
</style>